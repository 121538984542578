<template>
<div id="page-compras" class="non-printable">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height class="non-printable">
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="library_books" title="Ajuste Masivo de Precio" class="elevation-1 px-5 py-3">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn fab dark x-small color="secondary" @click.native="openModal(1)" title="Crear Aumento" v-tippy>
                            <v-icon dark>add</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-card-text>
                        <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                                <template slot="fecha" slot-scope="props">
                                    {{ parse_date_time(props.row.fecha) }}
                                </template>
                                <template slot="tipo_operacion" slot-scope="props">
                                    {{props.row.tipo_operacion[0].toUpperCase() + props.row.tipo_operacion.slice(1).toLowerCase()}}
                                </template>
                                <template slot="tipo_busqueda" slot-scope="props">
                                    {{props.row.tipo_busqueda[0].toUpperCase() + props.row.tipo_busqueda.slice(1).toLowerCase()}}
                                </template>
                                <template slot="precio_calculo" slot-scope="props">
                                    {{props.row.precio_calculo[0].toUpperCase() + props.row.precio_calculo.slice(1).toLowerCase()}}
                                </template>
                                <template slot="porcentaje" slot-scope="props">
                                    <div style="text-align:right">{{props.row.porcentaje}}%</div>
                                </template>

                                <template slot="articulos" slot-scope="props">
                                    <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                                        Ver {{ props.row.articulos.length }} artículos
                                    </v-chip>

                                </template>
                            </v-client-table>
                            <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

    <!-- MODAL BUSQUEDA -->
    <v-dialog v-model="modalBusqueda" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Buscar Artículos</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalBusqueda = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formajuste" lazy-validation>
                    <v-container grid-list-md>
                        <v-row>
                            <v-col sm="2" md="2" lg="2">
                                <v-autocomplete v-model="model.tipo_busqueda" :items="tipos_busqueda" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="id" ref="selectbusqueda" label="Buscar Por" :rules="[rules.required]" @change="setFocus()">
                                </v-autocomplete>
                            </v-col>
                            <v-col sm="8" md="8" lg="8">
                                <v-autocomplete v-if="model.tipo_busqueda=='articulo'" v-model="model.id_articulo" :items="articulos" :hide-no-data="true" :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo para buscar" prepend-icon="search" clearable item-text="nombre" item-value="_id" label="Artículo" @change="buscar_articulos" :rules="[model.tipo_busqueda=='articulo'? rules.required : '']">
                                </v-autocomplete>

                                <v-autocomplete v-if="model.tipo_busqueda=='proveedor'" v-model="model.id_proveedor" :items="proveedores" :hide-no-data="true" :loading="loadingProveedores" ref="selectproveedor" placeholder="Ingrese nombre de proveedor para buscar" prepend-icon="search" clearable item-text="nombre" item-value="_id" label="Proveedor" @change="buscar_articulos" :rules="[model.tipo_busqueda=='proveedor'? rules.required : '']">
                                </v-autocomplete>

                                <v-autocomplete v-if="model.tipo_busqueda=='categoria'" v-model="model.nombre_categoria" :items="categorias" :hide-no-data="true" :loading="loadingCategorias" ref="selectcategoria" placeholder="Ingrese nombre de categoría para buscar" prepend-icon="search" clearable item-text="nombre" item-value="nombre" label="Categoría" @change="buscar_articulos" :rules="[model.tipo_busqueda=='categoria'? rules.required : '']">
                                </v-autocomplete>

                            </v-col>
                            <v-col sm="2" md="2" lg="2">
                                <v-autocomplete v-model="model.tipo_precio" :items="tipos_precio" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="id" label="Tipos de Precio" :rules="[rules.required]" @change="buscar_articulos">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete v-model="model.descripciones" :items="descripciones" 
                                    :hide-no-data="true" :hide-selected="true" 
                                    multiple chips small-chips deletable-chips
                                    @change="buscar_articulos"
                                    :menu-props="{closeOnContentClick:true}"
                                    item-text="descripcion" item-value="descripcion" label="Filtro opcional de Descripciones">
                                </v-autocomplete>
                            </v-col>                            
                        </v-row>

                            <v-card>
                                <v-card-title>Opciones de Aplicación de Forma Masiva</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col sm="3" md="3" lg="3">
                                            <v-autocomplete v-model="model.precio_calculo" :items="precios_calculo" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="id" label="Precio de Cálculo" :rules="[rules.required]" @change="buscar_articulos">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="3">
                                            <v-autocomplete v-model="model.tipo_operacion" :items="aumento_decremento" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="id" label="Tipo Operación" :rules="[rules.required]" @change="buscar_articulos">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="2">
                                            <v-text-field label="Porcentaje" v-model="model.porcentaje" :rules="[rules.required,rules.decimal]" @input="buscar_articulos"></v-text-field>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="2">
                                            <v-checkbox v-model="model.usar_redondeo" :label="`¿Usar Redondeo ${this.model.redondeo}?`" :true-value="true" :false-value="false" @change="buscar_articulos"></v-checkbox>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="2">
                                            <v-spacer></v-spacer>
                                            <!--  <v-btn @click.native="buscar_articulos" color="success">
                                      <v-icon>search</v-icon> Buscar
                                    </v-btn> -->
                                            <v-btn color="success" @click.native="aplicar_operacion">
                                                <v-icon>done</v-icon> Aplicar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-spacer></v-spacer>
                                </v-card-text>

                            </v-card>
                            <br/>
                        <v-row>
                            <v-col>
                                <v-client-table ref="vuetablearticulos" :data="articulos_encontrados" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                                    <template slot="noResults">
                                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                    </template>
                                    
                                    <template slot="precio_compra_con_iva" slot-scope="props">
                                        <div style="text-align:right">${{props.row.precio_compra_con_iva.toFixed(2)}}</div>
                                    </template>

                                    <template slot="precio_venta.precio_con_impuestos" slot-scope="props">
                                  <div style="text-align:right">
                                    <v-chip x-small color="gray" text-color="black" title="Cambiar Precio" v-tippy @click="cambiar(props.row, props.index)">
                                          ${{props.row.precio_venta.precio_con_impuestos.toFixed(2)}}
                                    </v-chip>
                                  </div>
                                </template>
                                    <template slot="precio_nuevo" slot-scope="props">
                                        <div style="text-align:right">${{props.row.precio_nuevo.toFixed(2)}}</div>
                                    </template>
                                    <template slot="diferencia_pesos" slot-scope="props">
                                        <div style="text-align:right">${{(props.row.precio_nuevo - props.row.precio_compra_con_iva).toFixed(2)}}</div>
                                    </template>
                                    <template slot="diferencia_porcentaje" slot-scope="props">
                                        <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right">{{(100).toFixed(2)}}%</div>
                                        <div v-else style="text-align:right">{{(((props.row.precio_nuevo/props.row.precio_compra_con_iva)-1)*100).toFixed(2)}}%</div>
                                    </template>
                                </v-client-table>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

            </v-card-text>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modalArticulos" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Artículos Afectados</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalArticulos = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-client-table ref="vuetablearticulos" :data="articulos_vista" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                                <template slot="precio_compra_con_iva" slot-scope="props">
                                    <div style="text-align:right">${{props.row.precio_compra_con_iva.toFixed(2)}}</div>
                                </template>
                                <template slot="precio_venta.precio_con_impuestos" slot-scope="props">
                                        <div style="text-align:right">${{props.row.precio_venta.precio_con_impuestos.toFixed(2)}}</div>
                                    </template>

                                <template slot="precio_nuevo" slot-scope="props">
                                    <div style="text-align:right">${{props.row.precio_nuevo.toFixed(2)}}</div>
                                </template>
                                <template slot="diferencia_pesos" slot-scope="props">
                                        <div style="text-align:right">${{(props.row.precio_nuevo - props.row.precio_compra_con_iva).toFixed(2)}}</div>
                                    </template>
                                    <template slot="diferencia_porcentaje" slot-scope="props">
                                        <div v-if="props.row.precio_compra_con_iva==0" style="text-align:right">{{(100).toFixed(2)}}%</div>
                                        <div v-else style="text-align:right">{{(((props.row.precio_nuevo/props.row.precio_compra_con_iva)-1)*100).toFixed(2)}}%</div>
                                    </template>
                            </v-client-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalArticulos=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                <v-btn dark color="blue" v-on:click="solicitudEtiqueta(articulos_vista)" title="Crear Solicitud de Etiquetas" v-tippy>
                    <v-icon dark>mdi-cash-refund</v-icon>Solicitud de Etiquetas
                </v-btn>
                <v-btn color="success" dark @click.native="modalPlantilla = true">
                    <v-icon dark>done</v-icon> Plantilla de Precios
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="modalPlantilla" max-width="40%" persistent>
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">mdi-file-word</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Generar Plantilla Word de Precios</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalPlantilla = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formplantilla" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete v-model="tipo_plantilla" :items="tipos_plantilla" :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="_id" label="Seleccione Tipo de Plantilla" :rules="[rules.required]">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalPlantilla=false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                <v-btn color="success" @click.native="generaPlantilla">
                    <v-icon>done</v-icon> Generar Plantilla
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
     <!-- modal cantidad -->
     <v-dialog v-model="modalCantidad" max-width="500px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Precio</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalCantidad = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{model_editable.nombre_articulo}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                {{precio_editable}}
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col sm="6" md="6" lg="6" style="text-align:center !important;">
                                <v-text-field label="Nuevo Precio" v-model="cantidad_editable" ref="ingresacantidad" v-on:keydown.enter.prevent='1' @keyup.enter="guardar()" :rules="[validaCantidadFloatRequerido]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalCantidad = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn color="success" @click.native="guardar()">
                                <v-icon>done</v-icon> Aceptar
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import "jsbarcode";
/*import { saveAs } from "file-saver";
import { Document,
  Packer,
  Paragraph,
  TextRun,
  SectionType,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  HeadingLevel,
  AlignmentType,
  convertMillimetersToTwip,
  ImageRun
} from "docx"; */

export default {
    components: {
        "PaginacionComponent": PaginacionComponent
    },
    watch: {
        model_filters: {
            handler(val) {
                // this.$nextTick(() => {
                //   this.registros.items = [];
                //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                // });
            },
            deep: true
        },
        search(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "estatus": "Activo",
                    "type": "articulos",
                },
                "use_index": "_design/7d6652dbf4254f0af6f48c9a54fe2f8afe9f3f0f"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.articulos = response.data.docs;
                    } else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
        searchProveedor(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "estatus": "Activo",
                    "type": "proveedores",
                },
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.proveedores = response.data.docs;
                    } else
                        this.proveedores = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
        searchCategoria(val) {
            //Si no ha escrito 2 letras o mas
            if (val == null || val.length < 2) return
            // Si todavía esta buscando
            if (this.isLoading) return
            this.isLoading = true
            let data = {
                "selector": {
                    "nombre": {
                        "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    },
                    "type": "categorias",
                    "estatus": "Activo"
                },
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.categorias = response.data.docs;
                    } else
                        this.categorias = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(() => {
                    this.isLoading = false;
                });
        },
    },
    mounted: function () {
        this.model_filters = this.$refs.vuetable.$refs.table.query;
        if (!this.verificaPermiso('t.precios.ajustar.precios')) {
            this.$router.replace("AccessDenied").catch(() => {});
        }
    },
    created: function () {
        this.usuario = this.$local_storage.get("sb_usuario");
        this.get_configuracion();
        this.get_plantillas();
        this.getDescripciones();
        this.getArticulos();
        this.getProveedoresCombo();
        this.getCategorias();        
        this.model = this.clean_model();
    },
    data() {
        return {
            modalCantidad:false,
            loadingCategorias: false,
            loadingArticulos: false,
            loadingProveedores: false,
            tipo_plantilla: "",
            tipos_plantilla: [],
            model_editable: {},
            precio_editable: "",
            cantidad_editable: "",
            /*tipos_plantilla: [
              {"nombre":"Hoja Grande","tipo":"hoja_grande"},
              {"nombre":"Media Hoja","tipo":"media_hoja"},
              {"nombre":"Cuarto de Hoja","tipo":"cuarto_hoja"},
              {"nombre":"Cenefas PRO","tipo":"cenefas_pro"},
              {"nombre":"Cenefa Grande","tipo":"cenefa_grande"},
              {"nombre":"Mesa Grande","tipo":"mesa_grande"},
              {"nombre":"Mesa Morado","tipo":"mesa_morado"},
            ],*/
            modalPlantilla: false,
            
            tipos_precio: [{
                "id": "Todos",
                "nombre": "Todos"
            }],
            precios_calculo: [{
                "id": "venta",
                "nombre": "Venta"
            }, {
                "id": "compra",
                "nombre": "Compra"
            }],
            redondeo: 0,
            modalSugeridos: false,
            tipo_busqueda: "proveedor",
            aumento_decremento: [{
                    "id": "aumento",
                    "nombre": "Aumento"
                },
                {
                    "id": "decremento",
                    "nombre": "Decremento"
                }
            ],
            tipos_busqueda: [{
                    "id": "proveedor",
                    "nombre": "Proveedor"
                },
                {
                    "id": "categoria",
                    "nombre": "Categoría"
                },
                {
                    "id": "articulo",
                    "nombre": "Artículo"
                },
            ],
            isLoading: false,
            usuario: "",
            model_filters: "",
            props_paginacion: {
                infiniteHandler: this.infiniteHandler,
                total_registros: 0
            },
            fab: [],
            modalBusqueda: false,
            modalArticulos: false,
            columns: [
                "fecha", "usuario", "tipo_busqueda", "tipo_operacion", "precio_calculo", "tipo_precio", "porcentaje", "articulos"
            ],
            options: {
                filterable: ["fecha", "usuario", "tipo_busqueda", "tipo_operacion", "precio_calculo", "tipo_precio", "porcentaje"],
            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Precios",
                    href: "",
                    disabled: true,
                },
                {
                    text: "Ajustar Precios",
                    href: ""
                }
            ],
            articulos_raw: [],
            update: false,
            descripciones:[],
            model: {},
            columnsArticulos: ["nombre","descripcion", "precio_compra_con_iva", "precio_venta.nombre", "precio_venta.precio_con_impuestos", "precio_nuevo", "diferencia_pesos", "diferencia_porcentaje"],
            optionsArticulos: {
                filterable: ["nombre","descripcion", "precio_compra_con_iva", "precio_venta.nombre", "precio_venta.precio_con_impuestos", "precio_nuevo"],
                headings: {
                    "nombre": "Artículo",
                    "precio_compra_con_iva": "Precio de Compra",
                    "precio_venta.nombre": "Tipo de Precio",
                    "precio_venta.precio_con_impuestos": "Precio de Venta",
                    "precio_nuevo": "Precio Nuevo",
                    "diferencia_pesos": "Utilidad $",
                    "diferencia_porcentaje": "Utilidad %"
                },
                rowClassCallback: function (row) {
                    return row.seleccionado == 1 ? 'bg-seleccionado' : 'bg-default'
                },
            },
            rules: {
                required: v => !!v || "Este campo es requerido",
                decimal: value => {
                    const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
                    return pattern.test(value) || "Número decimal no válido";
                },
            },
            search: "",
            searchProveedor: "",
            searchCategoria: "",
            registros: {
                selected: [],
                items: []
            },
            articulos: [],
            proveedores: [],
            categorias: [],
            articulos_encontrados: [],
            articulos_vista: []
        };
    },
    methods: {
      cambiar: async function (row, index) {
            this.model_row = row;

            this.model_index = index - 1;
            this.cantidad_editable = "";
            this.precio_editable = row.precio_venta.nombre;
            await window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
                .then(response => {

                    this.model_editable = response.data;
                    this.modalCantidad = true;

                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresacantidad.focus());
                    }, 100);

                })
                .catch(error => {
                    console.log(error);
                    window.dialogLoader.hide();
                });

        },
        guardar: function () {
            let self = this;
            var x = this.model_editable;
            var publico = "N/A";
            var mayoreo = "N/A";
            var mayoreo2 = "N/A";
            if (x.precios_venta != undefined && x.precios_venta.length > 0) {                
                x.precios_venta.forEach(function (y) {
                    //console.log("Precios: ", x);
                    if (y.tipo_precio == "Público General") {
                        if(y.precio_con_impuestos != undefined)
                            publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                        else
                            publico = parseFloat(0.00).toFixed(2);
                    }
                    if (y.tipo_precio == "Mayoreo") {
                        if(y.precio_con_impuestos != undefined)
                            mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                        else
                            mayoreo = parseFloat(0.00).toFixed(2);
                    }
                    if (y.tipo_precio == "Mayoreo 2") {
                        if(y.precio_con_impuestos != undefined)
                            mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                        else 
                            mayoreo2 = parseFloat(0.00).toFixed(2);
                    }
                })
            }
            
            //validar que el precio de Mayore 2, no sea mayor a Mayoreo, y Mayoreo no sea mayor a Público
            if(this.precio_editable == "Mayoreo 2" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(mayoreo.toString()!='N/A' && parseFloat(mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(publico.toString()!='N/A' && parseFloat(publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo 2 es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Mayoreo" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(publico.toString()!='N/A' && parseFloat(publico) > 0){
                    if(parseFloat(this.cantidad_editable) > parseFloat(publico)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio de Mayoreo es mayor a Público General. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }else if(this.precio_editable == "Público General" && this.cantidad_editable.toString()!='N/A' && parseFloat(this.cantidad_editable) > 0){
                if(mayoreo2.toString()!='N/A' && parseFloat(mayoreo2) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(mayoreo2)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo 2. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
                if(mayoreo.toString()!='N/A' && parseFloat(mayoreo) > 0){
                    if(parseFloat(this.cantidad_editable) < parseFloat(mayoreo)){
                        this.$swal({
                            type: "warning",
                            title: "Cuidado",
                            text: "El precio Públigo General es menor a Mayoreo. Favor de verificar.",
                            footer: "",                            
                        });
                        return;
                    }
                }
            }
            
            window.dialogLoader.show('Espere un momento por favor..');

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE
              + "/_design/funciones_articulos/_update/actualizar_precio_venta/" + this.model_row._id;

            var data = {
                tipo_precio: this.precio_editable,
                precio_con_impuestos: parseFloat(this.cantidad_editable),
            };

            window.axios
            .post(url, data)
            .then(async response => {
                if (response.data == true) {
                    window.dialogLoader.showSnackbar('El precio se actualizó correctamente..', {
                        color: 'success'
                    });

                    //creamos solicitud de etiqueta
                    var art = {
                            id_articulo: self.model_row._id,
                            nombre_articulo: self.model_row.nombre,
                            descripcion: "",
                            codigo_articulo: [self.model_row.codigo],
                            cantidad: 1,
                            precio: parseFloat(self.model_row.precio_nuevo),
                            tipo_precio: self.model_row.precio_venta.nombre,
                            mayoreo: self.model_row.precio_venta.de
                        };
                    var etq = {
                        type: "etiquetas",
                        fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                        estatus: "Pendiente",
                        articulos: [art],
                        usuario: self.$local_storage.get("sb_usuario"),
                        observaciones: "Aumentos Masivos - Ajuste de Precio de un Artículo",
                    };

                    window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
                        .then(response => {

                        })
                        .catch(error => {
                            console.log(error);
                            window.dialogLoader.showSnackbar('Ocurrio un error al crear la solicitud', {
                                color: 'error'
                            });
                        })

                    this.modalCantidad = false;
                    await this.getArticulos();


                } else {
                    console.log("Precio No Actualizado");
                }
            })
            .catch(error => {
                console.log("Error: ",error);
                window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                    color: 'error'
                });
            }).then(() => {
                this.modal = false;
                window.dialogLoader.hide();
            });

        },
        validaCantidadFloatRequerido: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && value != "" && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) <= 0)
                    return "Debe ser mayor que cero";
            } else if (value != null && value == "")
                return "Campo requerido";
            return true;
        },
        solicitudEtiqueta: function (data) {
            window.dialogLoader.show('Espere un momento por favor..');
            var self = this;

            var articulos = [];
            data.forEach(function (r) {
                articulos.push({
                    id_articulo: r._id,
                    nombre_articulo: r.nombre,
                    descripcion: "",
                    codigo_articulo: [r.codigo],
                    cantidad: 1,
                    precio: parseFloat(r.precio_nuevo),
                    tipo_precio: r.precio_venta.nombre,
                    mayoreo: r.precio_venta.de
                });
            });

            var etq = {
                type: "etiquetas",
                fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                estatus: "Pendiente",
                articulos: articulos,
                usuario: self.$local_storage.get("sb_usuario"),
                observaciones: "Aumentos Masivos",
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
                .then(response => {
                    window.dialogLoader.showSnackbar('Solicitud Enviada', {
                        color: 'success'
                    });

                })
                .catch(error => {
                    console.log(error);
                    window.dialogLoader.showSnackbar('Ocurrio un error al crear la solicitud', {
                        color: 'error'
                    });
                }).then(()=>{
                  window.dialogLoader.hide();
                });

        },
        infiniteHandler: function ($state) {
            var rows_per_page = 10;
            var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
            var skip = page * rows_per_page;
            var where = {
                type: "aumentos_masivos"
            };

            this.options.filterable.forEach(x => {
                if (this.model_filters[x]) {
                    where[x] = {
                        "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    }
                }
            });

            window.axios({
                    method: 'POST',
                    url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                    withCredentials: true,
                    data: {
                        limit: rows_per_page,
                        skip: skip,
                        selector: where,
                    },
                }).then(response => {
                    if (response.data.docs.length) {
                        var unique_records = new Set(this.registros.items.map(item => item._id));
                        unique_records = Array.from(unique_records.values());            
                        var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
                        this.registros.items = this.registros.items.concat(nuevos);
                        this.registros.items.sort(function (a, b) {
                            //Ordenar por fecha más reciente
                            return new Date(b.fecha) - new Date(a.fecha);
                        });
                        this.props_paginacion.total_registros = this.registros.items.length;
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setFocus: function () {
            if (this.model.tipo_busqueda == "proveedor") {
                this.model.id_articulo = "";
                this.model.nombre_categoria = "";
                this.$nextTick(() => this.$refs.selectproveedor.focus());
            } else if (this.model.tipo_busqueda == "categoria") {
                this.model.id_articulo = "";
                this.model.id_proveedor = "";
                this.$nextTick(() => this.$refs.selectcategoria.focus());
            } else {
                this.model.id_proveedor = "";
                this.model.nombre_categoria = "";
                this.$nextTick(() => this.$refs.selectarticulo.focus());
            }
        },
        getArticulos: async function () {
            this.loadingArticulos = true;
            window.dialogLoader.show('Espere un momento por favor..');
            let data = {
                "selector": {
                    "type": "articulos",
                    "estatus": "Activo",
                },                
           //     "fields":["_id","nombre","codigo_barras", "precios_venta", "precio_compra_con_iva", "proveedores"],
                "use_index": "_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };
            await window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    //response.data.docs.sort(this.ordenar_nombre);
                    this.articulos = response.data.docs;                    
                    this.buscar_articulos();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                }).then(() => {
                    this.loadingArticulos = false;
                    window.dialogLoader.hide();
                });
        },
        getProveedoresCombo() {
            this.loadingProveedores = true;
            let data = {
                "selector": {
                    "type": "proveedores",
                    "estatus": "Activo"
                },
                "fields": ["_id", "nombre"],
                "use_index": "_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    //response.data.docs.sort(this.ordenar_nombre);
                    this.proveedores = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingProveedores = false;
                });
        },
        getCategorias() {
            this.loadingCategorias = true;
            let data = {
                "selector": {
                    "type": "categorias",
                    "estatus": "Activo"
                },
                "use_index": "_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.categorias = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingCategorias = false;
                });
        },
        getDescripciones: function() {
      let data = {
        "selector": {
          "type":"descripciones",          
        },        
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
            response.data.docs.sort(this.ordenar_descripcion);
            this.descripciones = response.data.docs;
          } else
            this.descripciones = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las descripciones.",
            footer: ""
          });
          console.log(error);
        });
    },    
    ordenar_descripcion: function( a, b ){
        if ( a.descripcion.toLowerCase() < b.descripcion.toLowerCase()){
            return -1;
        }
        if ( a.descripcion.toLowerCase() > b.descripcion.toLowerCase()){
            return 1;
        }
        return 0;
    },
        get_plantillas: function () {

      let data = {
        "selector": {"type":"plantillas", "estatus":"Activo","tipo_plantilla":"Precios"},
      };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        response.data.docs.sort(this.ordenar_nombre);
                        this.tipos_plantilla = response.data.docs;
                    } else
                        this.tipos_plantilla = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las plantillas.",
                        footer: ""
                    });
                });
        },
        ordenar_nombre: function (a, b) {
            if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
                return -1;
            }
            if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
                return 1;
            }
            return 0;
        },
        
        buscar_articulos: function () {
            var self = this;
            /*  if (!this.$refs.formajuste.validate()) {
               return false;
             } */
            window.dialogLoader.show('Espere un momento por favor..');
            var filtro = [];
            if (this.model.tipo_busqueda == 'articulo' && self.model.id_articulo!="") {

                if (this.model.tipo_precio != 'Todos') {
                    filtro = this.articulos.filter(function (a) {
                        return a._id == self.model.id_articulo && JSON.stringify(a.precios_venta).indexOf(self.model.tipo_precio) != -1;
                    });
                } else {
                    filtro = this.articulos.filter(function (a) {
                        return a._id == self.model.id_articulo;
                    });
                }

            } else if (this.model.tipo_busqueda == 'proveedor' && self.model.id_proveedor!="") {
                if (this.model.tipo_precio != 'Todos') {
                    filtro = this.articulos.filter(function (a) {
                        return JSON.stringify(a.proveedores).indexOf(self.model.id_proveedor) != -1 && JSON.stringify(a.precios_venta).indexOf(self.model.tipo_precio) != -1;
                    });
                } else {
                    filtro = this.articulos.filter(function (a) {
                        return JSON.stringify(a.proveedores).indexOf(self.model.id_proveedor) != -1;
                    });
                }
            } else if (this.model.tipo_busqueda == 'categoria' && self.model.nombre_categoria!="") {
                if (this.model.tipo_precio != 'Todos') {
                    filtro = this.articulos.filter(function (a) {
                        return a.categoria.includes(self.model.nombre_categoria) && JSON.stringify(a.precios_venta).indexOf(self.model.tipo_precio) != -1;
                    });
                } else {
                    filtro = this.articulos.filter(function (a) {
                        return a.categoria.includes(self.model.nombre_categoria);
                    });
                }
            } else {
                //console.log("aca en el false")
                window.dialogLoader.hide();
                return false;
            }

            if(self.model.descripciones.length > 0){
                filtro = filtro.filter(function(a){
                    return self.model.descripciones.includes(a.descripcion);
                });
            }            

            self.articulos_raw = JSON.parse(JSON.stringify(filtro));
            self.articulos_encontrados = this.limpiar_datos(JSON.parse(JSON.stringify(filtro)));
            window.dialogLoader.hide();

        },
        limpiar_datos: function (datos) {
            let response = [];
            datos.forEach((item) => {
                item.precios_venta.forEach((precios) => {
                    var precio_nuevo = 0;
                    item.precio_compra_con_iva = parseFloat(item.precio_compra_con_iva);

                    if (this.model.tipo_precio == 'Todos' || this.model.tipo_precio == precios.tipo_precio) {
                        precios.precio_con_impuestos = parseFloat(precios.precio_con_impuestos);                        

                        if (this.model.precio_calculo == "venta") {
                            let operacion = (precios.precio_con_impuestos * this.model.porcentaje) / 100;
                            precio_nuevo = this.model.tipo_operacion == 'aumento' ? precios.precio_con_impuestos + operacion : precios.precio_con_impuestos - operacion;
                        } else {
                            let operacion = (item.precio_compra_con_iva * this.model.porcentaje) / 100;
                            precio_nuevo = this.model.tipo_operacion == 'aumento' ? item.precio_compra_con_iva + operacion : item.precio_compra_con_iva - operacion;
                        }

                        if (this.model.usar_redondeo) {
                            precio_nuevo = (precio_nuevo % 1) >= this.model.redondeo ? Math.ceil(precio_nuevo) : Math.floor(precio_nuevo);
                        }
                        if(parseFloat(this.model.porcentaje)==0){
                          precio_nuevo = precios.precio_con_impuestos;
                        }
                        var item_datos = {
                            "_id": item._id,
                            "nombre": item.nombre,
                            "descripcion": item.descripcion,
                            "codigo": item.codigo_barras[0],
                            "proveedores": item.proveedores,
                            "precio_compra_con_iva": item.precio_compra_con_iva,
                            "precio_venta": {
                                "nombre": precios.tipo_precio,
                                "precio_con_impuestos": precios.precio_con_impuestos,
                                "de": precios.de,
                                "a":precios.a,
                            },
                            "precio_nuevo": precio_nuevo,
                            "porcentaje": precios.porcentaje,
                        };

                        //Si es sobre el precio de compra y es un aumento se guarda el porcentaje en los precios del articulo
                        if (this.model.precio_calculo != "venta" && this.model.tipo_operacion == 'aumento') {
                            item_datos.porcentaje = parseFloat(this.model.porcentaje);
                        }
                        response.push(item_datos);
                    }
                });

            });

            return response;

        },
        parse_date_time(date) {
            if (!date) return null;
            return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
        },
        verArticulos: function (row) {
            this.model_vista = row;
            this.articulos_vista = row.articulos;
            this.modalArticulos = true;
        },
        openModal: function (type) {
            if (type === 1) {
                this.update = false;
            }
            this.model = this.clean_model();
            //this.categorias = [];
            //this.proveedores = [];
            //this.articulos = [];
            this.articulos_raw = [];
            this.articulos_encontrados = [];

            this.modalBusqueda = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.selectbusqueda.focus());
            }, 100);
        },

        get_configuracion: function () {
            let data = {
                "selector": {
                    "type": "configuracion"
                },
                "fields": ["redondeo", "tipos_precio"]
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs[0].tipos_precio.forEach((item) => {
                        if (item.estatus == "Activo") {
                            this.tipos_precio.push({
                                "id": item.nombre,
                                "nombre": item.nombre
                            });
                        }
                    });
                    this.redondeo = response.data.docs[0].redondeo != undefined ? response.data.docs[0].redondeo : 0;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los registros.",
                        footer: ""
                    });
                });
        },
        aplicar_operacion: async function () {
            if (!this.$refs.formajuste.validate()) {
                return false;
            }
            window.dialogLoader.show('Espere un momento por favor..');
            let url_normal = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/";

            if (this.articulos_encontrados.length > 0) {

              var errores = 0;
                for(var k in this.articulos_encontrados){
                  var art = this.articulos_encontrados[k];

                  var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE
                      + "/_design/funciones_articulos/_update/actualizar_precio_venta/" + art._id;

                    var data = {
                        tipo_precio: art.precio_venta.nombre,
                        precio_con_impuestos: parseFloat(art.precio_nuevo),
                    };

                    await window.axios
                      .post(url, data)
                      .then(async response => {
                          if (response.data != true) {
                              errores += 1;
                          }
                      })
                      .catch(error => {
                          console.log("Error: ",error);
                          errores += 1;
                      });
                }

                if( errores > 0){
                  window.dialogLoader.hide();
                  this.$swal({
                      type: "warning",
                      title: "¡Operación no Completada!",
                      text: "Algunos precios no se actualizaron. Intente nuevamente",
                      footer: ""
                  });
                } else {
                  this.model.usuario = this.$local_storage.get("sb_usuario");
                  this.model.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                  this.model.articulos = this.articulos_encontrados;
                  delete this.model._id;
                  delete this.model._rev;

                  await window.axios
                      .post(url_normal, this.model)
                      .then(response => {
                          this.$swal({
                              type: "success",
                              title: "¡Operación Exitosa!",
                              text: "Los cambios se guardaron correctamente.",
                              footer: ""
                          });
                          this.registros.items = [];
                          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                      })
                      .catch(error => {
                          this.$swal({
                              type: "error",
                              title: "¡Operación no Permitida!",
                              text: "Ocurrió un error al guardar el registro.",
                              footer: ""
                          });
                      }).then(() => {
                        this.modalBusqueda = false;
                        window.dialogLoader.hide();
                        this.getArticulos();
                    });

                }
                //await this.getArticulos();


            } else {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "No hay articulos para aplicar el cambio",
                    footer: ""
                });
                window.dialogLoader.hide();
            }
        },
        clean_model: function () {
            return {
                _id: "",
                _rev: "",
                id_articulo: '',
                id_proveedor: '',
                nombre_categoria: '',
                usar_redondeo: false,
                redondeo: this.redondeo,
                tipo_precio: "Todos",
                precio_calculo: "venta",
                tipo_operacion: "",
                porcentaje: 0,
                tipo_busqueda: "proveedor",
                fecha: "",
                articulos: [],
                usuario: "",
                type: "aumentos_masivos",
                descripciones: [],
            };
        },

        base64ToArrayBuffer: function (base64) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        },

        generaPlantilla: function () {
            if (this.$refs.formplantilla.validate()) {
                try {
                    window.dialogLoader.show('Espere un momento por favor..');

                    let data = {
                        "id": this.model_vista._id,
                        "plantilla": this.tipo_plantilla,
                    };
                    //console.log(data);
                    window.axios
                        .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/get_template_precios/", data, {
                            "Content-type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Allow": "POST",
                            "cache-control": "no-cache",
                            "responseType": "arraybuffer"
                        })
                        .then(response => {
                            var bytes = response.data;
                            let blob = new Blob([bytes], {
                                    type: response.headers["content-type"]
                                }),
                                url = window.URL.createObjectURL(blob);

                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = url;
                            a.download = "PlantillaPrecios.docx";
                            a.click();
                            a.parentNode.removeChild(a);
                        })
                        .catch(error => {
                            console.log(error);
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al generar la plantilla. Intente nuevamente.",
                                footer: ""
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                        });
                } catch (error) {
                    console.log("ERRORS: ", error);
                }

            }
        },

        /** Se GENERA WORD DIRECTO - EJEMPLO - */
        /*generaPlantilla: function(){
          window.dialogLoader.show('Espere un momento por favor..');

          var rowsArticulos = [];
          for(var k in this.articulos_vista){
              var img = document.createElement("img");
              JsBarcode(img, this.articulos_vista[k].codigo, {format: "CODE128"});

              const image = new ImageRun({
                  data: img.src,
                  transformation: {
                      width: 100,
                      height: 50,
                  },
              });

              const r = new TableRow({
                children: [
                    new TableCell({
                        verticalAlign: VerticalAlign.CENTER,
                        children: [
                          new Paragraph({
                              children: [new TextRun("$"), new TextRun(this.articulos_vista[k].precio_nuevo.toFixed(2) ),],
                              alignment: AlignmentType.CENTER
                          }),
                          new Paragraph({
                            children: [image],
                            alignment: AlignmentType.CENTER
                          }),
                          new Paragraph({
                              children: [new TextRun(this.articulos_vista[k].nombre),],
                              alignment: AlignmentType.CENTER
                          }),
                      ],
                      margins: {
                              top: convertMillimetersToTwip(5),
                              bottom: convertMillimetersToTwip(5),
                              left: convertMillimetersToTwip(5),
                              right: convertMillimetersToTwip(5),
                          },
                    })
                ]
              });
              rowsArticulos.push(r);
          }

          //TABLA
          const tabla = new Table({
              rows: rowsArticulos
          });

          const doc = new Document({
              sections: [{
                  properties: {},
                  children: [tabla],
                },
              ],
          });

          Packer.toBlob(doc).then((blob) => {
              console.log(blob);
              saveAs(blob, "Precios.docx");
              window.dialogLoader.hide();
          });
        }, */
    }
};
</script>

<style>
.footer-hide>>>.VuePagination {
    display: none;
}

.bg-seleccionado {
    /*background-color: #861306 !important;*/
    background-color: #EF9A9A !important;
}

.bg-resaltado {
    background-color: #FFF9C4 !important;
}
</style>
